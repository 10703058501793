import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import Codebox from "../../videos/codebox.mp4";
import Fade from "react-reveal/Fade";

const words = [
  "AI-Powered Chatbots",
  "Personalized Education",
  "Creative AI",
  "Character Design",
  "Jungian Archetypes",
];

const typeWriter = keyframes`
  0%, 10% { width: 0; }
  30%, 60% { width: 100%; }
  80%, 100% { width: 0; }
`;

const blinkCaret = keyframes`
  from, to { border-color: transparent }
  50% { border-color: #fff; }
`;

const TypingContainer = styled.div`
  height: 2em; // Adjust this value based on your needs
  /* margin-bottom: 20px; // Add some space between this and the next element */
  @media screen and (max-width: 768px) {
    height: 1em; // Adjust this value based on your needs
  }
`;

const HeroH11 = styled.h1`
  font-family: "VT323", monospace;
  font-weight: 900;
  color: #fff;
  font-size: 2em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
  margin: 0; // Remove any default margins
  padding: 0; // Remove any default padding
  line-height: 1; // Ensure consistent line height
  @media screen and (max-width: 768px) {
    font-size: 1em;
  }
`;

const ChangingWord = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid #fff;
  vertical-align: bottom;
  animation: ${typeWriter} ${(props) => props.duration}s
      steps(${(props) => props.length * 2}, end) infinite,
    ${blinkCaret} 0.75s step-end infinite;
  font-family: "VT323", monospace;
  font-weight: 900;
  color: #fff;
  font-size: 1em;
`;

const HeroSection = () => {
  const [word, setWord] = useState(words[0]);
  const typingDuration = 6; // Duration for the full animation cycle of one word

  useEffect(() => {
    const wordInterval = setInterval(() => {
      setWord((prevWord) => {
        const currentIndex = words.indexOf(prevWord);
        return words[(currentIndex + 1) % words.length];
      });
    }, typingDuration * 1000);

    return () => clearInterval(wordInterval);
  }, []);

  return (
    <HeroContainer id="home">
      <HeroBg>
        <VideoBg
          playsInline
          autoPlay
          loop
          muted
          src={Codebox}
          type="video/mp4"
        />
      </HeroBg>
      <HeroContent>
        <WordContainer>
          <Fade top>
            <TypingContainer>
              <HeroH11>
                <ChangingWord length={word.length} duration={typingDuration}>
                  {word}
                </ChangingWord>
              </HeroH11>
            </TypingContainer>
          </Fade>
          <Fade bottom duration={1000}>
            <HeroH1>Integration Specialist</HeroH1>
          </Fade>
        </WordContainer>
        <Fade bottom duration={1500}>
          <Subheading>
            Seamlessly Merging Human Insight with Cutting-Edge Technology for
            Next-Gen Solutions
          </Subheading>
        </Fade>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;

const HeroContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
`;

const WordContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 4;
  /* margin-bottom: 10px; */
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1em;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    gap: 0em;
    font-size: 1.2rem;
  }
`;

const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const VideoBg = styled.video`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  top: 0;
  object-fit: cover;
  background-color: #232a34;
`;

const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeroH1 = styled.h1`
  font-family: "Orbitron", sans-serif;
  font-weight: 900;
  color: #45fe47;
  font-size: 2.5em;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* margin-top: 10px; */
  z-index: 3;
  @media screen and (max-width: 768px) {
    font-size: 2em;
  }
`;

const Subheading = styled.div`
  margin: 10px 0px 0px 0px;
  color: #fff;
  font-size: 1em;
  font-weight: 300;
  text-align: center;
  max-width: 500px;
  @media screen and (max-width: 480px) {
    margin-top: 0.5rem;
    width: 350px;
    font-size: 0.8em;
  }
`;
